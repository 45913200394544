<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="15">
            <a-form-item label="流程定义key">
              <a-input placeholder="请输入流程定义key" v-model="queryParam.procDefKey" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="15">
            <a-form-item label="申请标题">
              <a-input placeholder="请输入申请标题" v-model="queryParam.title" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="query">查询</a-button>
<!--              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>-->
              <a-button style="margin-left: 8px" @click="reset">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button :disabled="!selectedRowKeys.length > 0" type="danger" icon="delete" @click="delByIds(selectedRowKeys)"
        >删除</a-button
      >
    </div>
    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :row-selection="rowSelection"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="page"
      :rowClassName="rowClassName"
      @change="changeTable"
    >
      <span slot="name" slot-scope="text">
        <a-tooltip>
          <template slot="title"> {{ text }} </template> {{ text }}
        </a-tooltip>
      </span>
      <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
      <span slot="logo" slot-scope="item">
        <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
      </span>

      <span slot="action" slot-scope="text, record">
        <a v-if="removeEnable" @click="delByIds([record.id])">删除</a>
      </span>
    </a-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { millsToTime } from '@/utils/util'
import { getProfInfFinished, delHiProcessIns, resultMap } from '@/api/activiti'
import { checkPermission } from '@/utils/permissions'
import ACol from 'ant-design-vue/es/grid/Col'
export default {
  name: 'ProfList',
  components: {
    ACol,
    STable,
  },
  data() {
    return {
      tableData: [],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      tableLoading: false, //表格loading
      visible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      form: this.$form.createForm(this),
      mdl: {},
      permissions: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        // {
        //   title: '流程实例ID',
        //   dataIndex: 'id'
        // },
        {
          title: '申请标题',
          dataIndex: 'title',
        },
        {
          title: '流程名称',
          dataIndex: 'procName',
        },
        {
          title: '申请人',
          dataIndex: 'applyer',
        },
        {
          title: '标识key',
          dataIndex: 'procDefKey',
        },
        {
          title: '审批结果',
          dataIndex: 'result',
          scopedSlots: { customRender: 'result' },
        },
        {
          title: '耗时',
          dataIndex: 'duration',
          customRender: (text) => millsToTime(text),
        },
        {
          title: '结束原因',
          dataIndex: 'deleteReason',
        },
        {
          title: '开始时间',
          dataIndex: 'startTime',
        },
        {
          title: '结束时间',
          dataIndex: 'endTime',
        },
        {
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   return getProfInfFinished(Object.assign(parameter, this.queryParam))
      // },
      previewVisible: false,
      previewImage: '',
      selectedRowKeys: [],
      selectedRows: [],
      removeEnable: true,
      // removeEnable: checkPermission('activiti:prof:remove')
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          // props: {
          //   disabled: record.deploymentId, // Column configuration not to be checked
          //   name: record.name
          // }
        }),
      }
    },
  },
  filters: {
    resultFilter(result) {
      return resultMap[result]
    },
  },
  created() {
    this.getData()
  },
  methods: {
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleOk() {
      this.getData()
      console.log('handleSaveOk')
    },
    delByIds(ids) {
      const _that = this
      _that.$confirm({
        title: '确认删除',
        content: '确认要删除选择的流程吗?',
        onOk() {
          delHiProcessIns({ ids: ids.join(',') })
            .then((res) => {
              if (res.code === 200) {
                _that.$message.success(`删除成功`)
                _that.handleOk()
              } else {
                _that.$message.error(res.msg)
              }
            })
            .finally((_that.selectedRowKeys = []))
        },
        onCancel() {},
      })
    },

    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    // 查询
    query(){
      this.page.current = 1;
      this.getData();
    },

    reset(){
      this.queryParam = {}
      this.page.current = 1;
      this.page.pageSize = 10;
      this.getData()
    },

    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      return getProfInfFinished({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.queryParam,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  },
}
</script>
<style lang="less" scoped>
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>